import React from "react"
import { css } from "@emotion/core"
import Layout from "../components/layout"

import twitterLogo from "./twitter_logo.png"
import linkedInLogo from "./linkedin_logo.png"
import emailLogo from "./email_logo.png"

export default function Contact() {
  return (
    <Layout>
      <div
        css={css`
          height: 100vh;
        `}
      >
        <title> Contact - Josh Burns</title>
        <h1> Contact</h1>
        <span>
          <br />
          <a href="https://twitter.com/Josh_Burns_Tech" target="_blank">
            <img
              src={twitterLogo}
              css={css`
                filter: invert(1);
                width: 20%;
                max-width: 100px;
                padding: 2%;
              `}
            ></img>
          </a>
          <a
            href="https://www.linkedin.com/in/josh-burns-87421074/"
            target="_blank"
          >
            <img
              src={linkedInLogo}
              css={css`
                filter: invert(1);
                width: 20%;
                max-width: 100px;
                padding: 2%;
              `}
            ></img>
          </a>
          <a href="mailto:josh.burns1993@gmail.com" target="_blank">
            <img
              src={emailLogo}
              css={css`
                filter: invert(1);
                width: 20%;
                max-width: 100px;
                padding: 2%;
              `}
            ></img>
          </a>
        </span>
      </div>
    </Layout>
  )
}
